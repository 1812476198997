<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit Group 2" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Kode *" v-model="data.kode"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.nama"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Group 1 *</label>
            <v-select :options="group1s" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_item_group1"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ItemGroup2Repository from '@/repositories/master/item-group2-repository'
import ItemGroup1Repository from '@/repositories/master/item-group1-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'ItemGroup2Edit',
  props: ['isActive', 'item'],
  components: {
    ValidationErrors,
    'v-select': vSelect
  },
  mounted () {
    this.getAllItemGroup1()
  },
  watch: {
    isActive (value) {
      if (value === true) {
        this.data = Object.assign({}, this.item)
      }
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {},
      group1s: []
    }
  },
  methods: {
    getAllItemGroup1 () {
      ItemGroup1Repository.getAll()
        .then(response => {
          this.group1s = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      ItemGroup2Repository.update(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['group1s'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
